import React from "react"
import {
  FaCalendarAlt,
  FaStethoscope,
  FaFileInvoiceDollar,
} from "react-icons/fa"
import "./ProblemsSolutionsSection.css"

const ProblemsSolutionsSection = () => {
  return (
    <section
      id="problems-solutions"
      className="plantao-page-problems-solutions-section"
      aria-labelledby="problems-solutions-heading"
    >
      <div className="plantao-page-problems-solutions-content">
        <h2 id="problems-solutions-heading">
          Transforme a Gestão dos Seus Plantões com o Plantão Fácil
        </h2>
        <p className="plantao-page-subtitle">
          O sistema completo para médicos que atuam como Pessoa Jurídica em
          clínicas e hospitais. Simplifique sua rotina, maximize seu tempo e
          tenha controle total dos seus plantões.
        </p>
        <div className="plantao-page-problems-solutions-grid">
          <article
            className="plantao-page-problem"
            aria-labelledby="problem1-heading"
          >
            <div className="plantao-page-problem-icon">
              <FaCalendarAlt size={40} color="#159a80" />
            </div>
            <h3 id="problem1-heading">Gestão Ineficiente de Plantões</h3>
            <p>
              Reduza o tempo gasto com a gestão de plantões em até 50%. O
              Plantão Fácil permite uma organização intuitiva, com
              acessibilidade em qualquer dispositivo.
            </p>
          </article>
          <article
            className="plantao-page-problem"
            aria-labelledby="problem2-heading"
          >
            <div className="plantao-page-problem-icon">
              <FaStethoscope size={40} color="#159a80" />
            </div>
            <h3 id="problem2-heading">Falta de Integração com Telemedicina</h3>
            <p>
              Aumente sua eficiência com consultas remotas integradas,
              oferecendo atendimento seguro e rápido para seus pacientes sem
              sair da plataforma.
            </p>
          </article>
          <article
            className="plantao-page-problem"
            aria-labelledby="problem3-heading"
          >
            <div className="plantao-page-problem-icon">
              <FaFileInvoiceDollar size={40} color="#159a80" />
            </div>
            <h3 id="problem3-heading">Dificuldade em Gerenciar Recebíveis</h3>
            <p>
              Mantenha suas finanças sob controle com a automação de recebíveis
              e relatórios financeiros precisos. Chega de preocupações com
              pagamentos atrasados.
            </p>
          </article>
        </div>
        <div className="plantao-page-problems-cta">
          <a
            href="https://plantao.prontuariofacil.com.br/cadastrar"
            className="plantao-page-cta-button"
            role="button"
            id="botao-teste-gratis"
          >
            Crie Sua Conta e Experimente Grátis por 14 Dias
          </a>
        </div>
      </div>
    </section>
  )
}

export default ProblemsSolutionsSection
