import React from "react"
import "./BenefitsSection.css"

const BenefitsSection = () => {
  return (
    <section
      id="benefits"
      className="plantao-page-benefits-section"
      aria-labelledby="benefits-heading"
    >
      <div className="plantao-page-benefits-content">
        <h2 id="benefits-heading">
          Benefícios de Usar o Plantão Fácil para Médicos PJ
        </h2>
        <p className="plantao-page-benefits-description">
          O Plantão Fácil é o sistema definitivo para médicos que atuam como
          Pessoa Jurídica, facilitando a gestão de plantões, finanças e
          atendimentos. Descubra por que centenas de médicos já utilizam nossa
          plataforma para otimizar seu trabalho.
        </p>
        <div className="plantao-page-benefits-grid">
          <article
            className="plantao-page-benefit-item"
            aria-labelledby="benefit1-heading"
          >
            <h3 id="benefit1-heading">Gestão Eficiente de Plantões Médicos</h3>
            <p>
              Organize e simplifique seus plantões, evitando sobrecargas e
              conflitos de agenda. Aumente sua produtividade com uma plataforma
              intuitiva e acessível de qualquer dispositivo.
            </p>
          </article>
          <article
            className="plantao-page-benefit-item"
            aria-labelledby="benefit2-heading"
          >
            <h3 id="benefit2-heading">Telemedicina Integrada com Facilidade</h3>
            <p>
              Realize consultas online diretamente pela plataforma, otimizando o
              tempo e oferecendo mais conveniência aos seus pacientes com total
              segurança e praticidade.
            </p>
          </article>
          <article
            className="plantao-page-benefit-item"
            aria-labelledby="benefit3-heading"
          >
            <h3 id="benefit3-heading">Automatização de Recebíveis</h3>
            <p>
              Elimine a complexidade da gestão financeira com nossa ferramenta
              automatizada de controle de recebíveis, garantindo que seus
              pagamentos sejam gerenciados de forma eficiente.
            </p>
          </article>
          <article
            className="plantao-page-benefit-item"
            aria-labelledby="benefit4-heading"
          >
            <h3 id="benefit4-heading">
              Relatórios Financeiros e Análises de Desempenho
            </h3>
            <p>
              Acompanhe o desempenho financeiro da sua clínica com relatórios
              detalhados, permitindo que você tome decisões estratégicas para
              otimizar suas operações.
            </p>
          </article>
        </div>
        {/* CTA para incentivar ação imediata */}
        <div className="plantao-page-benefits-cta">
          <a
            href="https://plantao.prontuariofacil.com.br/cadastrar"
            className="plantao-page-cta-button"
            role="button"
            id="botao-teste-gratis"
          >
            Crie Sua Conta e Experimente 14 Dias Grátis
          </a>
        </div>
      </div>
    </section>
  )
}

export default BenefitsSection
