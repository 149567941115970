import React, { useState } from "react"
import "./FeaturesInteractiveSection.css"
import gestaoPlantoesMedicos from "../../assets/Interface-de-gestao-de-plantoes-medicos.webp"
import gestaoRecebiveisMedicos from "../../assets/Interface-de-gestao-de-plantoes-medicos.webp"
import TeleMedicinaGestao from "../../assets/Integraçao-de-telemedicina-na-gestao-medica.webp"
import relatoriosFinanceiros from "../../assets/relatorios-financeiros-avancados.webp"
import agendaMedica from "../../assets/agenda-medica.webp"
import validacaoReceitaMedicaDigital from "../../assets/validacao-de-receita-medica-digital.webp"
import receitaMedicaDigital from "../../assets/receita-medica-digital.webp"
import prontuarioDigital from "../../assets/prontuario-digital-seguro.webp"

const FeaturesInteractiveSection = () => {
  const features = [
    {
      name: "Gestão de Plantões Médicos Eficiente",
      description:
        "Automatize a gestão dos seus plantões médicos, reduzindo o tempo gasto em até 50% e evitando conflitos de horários. A interface intuitiva garante que sua equipe esteja sempre alinhada.",
      image: gestaoPlantoesMedicos,
      alt: "Interface para gestão de plantões médicos eficiente",
    },
    {
      name: "Integração Completa com Telemedicina",
      description:
        "Expanda seu atendimento com telemedicina integrada. Realize consultas online de forma segura, mantendo todas as informações sincronizadas na sua agenda e prontuário digital.",
      image: TeleMedicinaGestao,
      alt: "Interface de telemedicina integrada na gestão médica",
    },
    {
      name: "Gestão Financeira e de Recebíveis Médicos",
      description:
        "Controle todos os recebíveis da sua clínica em um só lugar, automatizando processos e garantindo uma saúde financeira sólida com relatórios detalhados.",
      image: gestaoRecebiveisMedicos,
      alt: "Interface para gestão de recebíveis médicos",
    },
    {
      name: "Relatórios Financeiros Avançados",
      description:
        "Obtenha insights financeiros detalhados com relatórios avançados, permitindo tomar decisões estratégicas para o crescimento e sustentabilidade da sua clínica médica.",
      image: relatoriosFinanceiros,
      alt: "Interface de relatórios financeiros detalhados",
    },
    {
      name: "Agenda Médica Sincronizada",
      description:
        "Mantenha sua agenda médica sempre atualizada e sincronizada em todos os dispositivos. Evite sobreposição de horários e otimize o atendimento aos seus pacientes.",
      image: agendaMedica,
      alt: "Interface de agenda médica sincronizada",
    },
    {
      name: "Prontuário Digital Seguro",
      description:
        "Acesse e gerencie os prontuários dos seus pacientes de forma segura e prática. Nossa plataforma está em conformidade com a LGPD, garantindo a segurança dos dados.",
      image: prontuarioDigital,
      alt: "Interface de prontuário digital seguro",
    },
    {
      name: "Receita Médica Digital",
      description:
        "Gere receitas médicas digitais de forma segura e em conformidade com as leis. Nossa plataforma permite assinatura digital, garantindo praticidade para médicos e pacientes.",
      image: receitaMedicaDigital,
      alt: "Interface de criação de receita médica digital",
    },
    {
      name: "Validação de Receita Médica",
      description:
        "Valide receitas médicas digitais de forma segura e rápida. Garanta a autenticidade das receitas com nossa ferramenta de validação integrada.",
      image: validacaoReceitaMedicaDigital,
      alt: "Interface de validação de receita médica digital",
    },
  ]

  const [selectedFeature, setSelectedFeature] = useState(features[0].name)

  const handleFeatureClick = feature => {
    setSelectedFeature(feature.name)
  }

  const selected = features.find(feature => feature.name === selectedFeature)

  return (
    <section
      id="features-interactive"
      className="plantao-page-features-interactive-section"
      aria-labelledby="features-interactive-heading"
    >
      <div className="plantao-page-features-interactive-content">
        <h2 id="features-interactive-heading">
          Explore Nossas Funcionalidades
        </h2>
        <div
          className="plantao-page-features-nav"
          role="tablist"
          aria-label="Funcionalidades do Plantão Fácil"
        >
          {features.map(feature => (
            <button
              key={feature.name}
              role="tab"
              aria-selected={selectedFeature === feature.name}
              aria-controls={`feature-${feature.name}`}
              id={`tab-${feature.name}`}
              className={`plantao-page-feature-button ${
                selectedFeature === feature.name ? "active" : ""
              }`}
              onClick={() => handleFeatureClick(feature)}
            >
              {feature.name}
            </button>
          ))}
        </div>
        {selected && (
          <div
            id={`feature-${selected.name}`}
            role="tabpanel"
            aria-labelledby={`tab-${selected.name}`}
            className="plantao-page-feature-details"
          >
            <img src={selected.image} alt={selected.alt} loading="lazy" />
            <p>{selected.description}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default FeaturesInteractiveSection
