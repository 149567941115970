import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';
import HeroSection from '../components/plantaoFacil/HeroSection';
import ProblemsSolutionsSection from '../components/plantaoFacil/ProblemsSolutionsSection';
import BenefitsSection from '../components/plantaoFacil/BenefitsSection';
import FeaturesInteractiveSection from '../components/plantaoFacil/FeaturesInteractiveSection';
import TestimonialsSection from '../components/plantaoFacil/TestimonialsSection';
import PricingSection from '../components/plantaoFacil/PricingSection';
import SecuritySection from '../components/plantaoFacil/SecuritySection';
import FaqSection from '../components/plantaoFacil/FaqSection';
import CtaSection from '../components/plantaoFacil/CtaSection';

import './plantao-facil.css';

const LandingPage = () => {
    const crumbs = [
        { path: '/', label: 'Página Inicial' },
        { path: '/plantao-facil', label: 'Plantão Fácil' }
    ];

    function toggleAccordion(event) {
        const button = event.target;
        const answer = button.nextElementSibling;

        button.classList.toggle('active');

        if (answer.style.maxHeight) {
            answer.style.maxHeight = null;
        } else {
            answer.style.maxHeight = answer.scrollHeight + 'px';
        }
    }

    return (
        <Layout>
            <div className="plantao-page-container">
                <Seo
                    title="Plantão Fácil - Software para Gestão de Plantões Médicos"
                    description="O Plantão Fácil é o software ideal para médicos que desejam gerenciar seus plantões, agenda médica e recebíveis de forma simplificada. Descubra como podemos transformar sua prática médica."
                    keywords="gestão de plantões médicos, software para médicos, agenda médica online, recebíveis médicos, sistema de plantões para clínicas"
                />
                <Breadcrumbs crumbs={crumbs} />
                <main className="plantao-page-content">
                    <HeroSection />
                    <ProblemsSolutionsSection />
                    <BenefitsSection />
                    <FeaturesInteractiveSection />
                    <TestimonialsSection />
                    <PricingSection />
                    <SecuritySection />
                    <FaqSection toggleAccordion={toggleAccordion} />
                    <CtaSection />
                </main>
            </div>
        </Layout>
    );
};

export default LandingPage;
