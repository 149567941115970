import React from 'react';
import './CtaSection.css';

const CtaSection = () => {
    return (
        <section className="plantao-page-cta-section" aria-labelledby="cta-heading">
            <div className="plantao-page-cta-content">
                <h2 id="cta-heading">Transforme Sua Gestão Médica com Plantão Fácil</h2>
                <p>
                    Aumente a eficiência da sua clínica e simplifique a gestão dos seus plantões. Experimente todas as funcionalidades do Plantão Fácil e veja como ele pode melhorar sua rotina. Não perca essa oportunidade!
                </p>
                <a 
                    href="https://plantao.prontuariofacil.com.br/cadastrar" 
                    className="plantao-page-cta-button" 
                    role="button" 
                    id="botao-cadastrar-conta" 
                    aria-label="Cadastrar-se Gratuitamente no Plantão Fácil com desconto de 20% nos primeiros 3 meses"
                >
                    Comece Gratuitamente Agora
                </a>
                <p className="plantao-page-cta-urgency">
                    <strong>*Oferta por tempo limitado:</strong> Garanta 20% de desconto nos primeiros 3 meses de assinatura. Cadastre-se agora e aproveite!
                </p>
            </div>
        </section>
    );
};

export default CtaSection;
