import React, { useState } from "react"
import "./FaqSection.css"
import { FiPlus, FiMinus } from "react-icons/fi"

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleAccordion = index => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const faqs = [
    {
      question: "O que é o Plantão Fácil?",
      answer:
        "O Plantão Fácil é um software completo para a gestão de plantões médicos. Ele permite que profissionais de saúde organizem suas agendas, controlem seus recebíveis e gerenciem suas equipes de forma eficiente e segura.",
    },
    {
      question: "Como posso começar a usar o Plantão Fácil?",
      answer:
        "Você pode começar a usar o Plantão Fácil de forma gratuita. Basta se cadastrar no nosso site, e você terá acesso imediato às funcionalidades básicas para organizar seus plantões e controlar seus atendimentos.",
    },
    {
      question: "O Plantão Fácil é compatível com telemedicina?",
      answer:
        "Sim, o Plantão Fácil integra a telemedicina, permitindo que você realize consultas online de forma segura, diretamente pela sua agenda médica.",
    },
    {
      question: "Quais são os planos disponíveis e os preços?",
      answer:
        "Oferecemos três planos: o Plano Básico (gratuito), o Plano Profissional (R$ 49/mês) e o Plano Empresarial (R$ 199/mês). Cada plano oferece funcionalidades específicas, como telemedicina avançada e geração de receitas digitais.",
    },
    {
      question: "Como o Plantão Fácil protege meus dados?",
      answer:
        "O Plantão Fácil utiliza criptografia de ponta a ponta e segue as melhores práticas de segurança para proteger seus dados e os de seus pacientes, em conformidade com a LGPD.",
    },
    {
      question: "Posso cancelar minha assinatura a qualquer momento?",
      answer:
        "Sim, você pode cancelar sua assinatura a qualquer momento sem custos adicionais diretamente no painel do usuário.",
    },
  ]

  return (
    <section id="faq" className="faq-section" aria-labelledby="faq-heading">
      <div className="faq-content">
        <h2 id="faq-heading" className="faq-title">
          Perguntas Frequentes
        </h2>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "open" : ""}`}
              onClick={() => toggleAccordion(index)}
              onKeyPress={e =>
                (e.key === "Enter" || e.key === " ") && toggleAccordion(index)
              }
              tabIndex={0}
              role="button"
              aria-expanded={activeIndex === index}
              aria-controls={`faq-answer-${index}`}
            >
              <div className="faq-question">
                <h3 id={`faq-question-${index}`}>{faq.question}</h3>
                <span>{activeIndex === index ? <FiMinus /> : <FiPlus />}</span>
              </div>
              {activeIndex === index && (
                <div
                  className="faq-answer"
                  id={`faq-answer-${index}`}
                  role="region"
                  aria-labelledby={`faq-question-${index}`}
                >
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FaqSection
