import React from "react"
import "./PricingSection.css"

const PricingSection = () => {
  return (
    <section
      id="pricing"
      className="plantao-page-pricing-section"
      aria-labelledby="pricing-heading"
    >
      <div className="plantao-page-pricing-content">
        <h2 id="pricing-heading">
          Escolha o Plano Ideal para Sua Prática Médica
        </h2>
        <p className="plantao-page-pricing-description">
          Oferecemos planos flexíveis e com funcionalidades adaptáveis para sua
          rotina médica. Experimente o plano que melhor atende às suas
          necessidades.
        </p>
        <div className="plantao-page-pricing-grid">
          {/* Plano Básico */}
          <article
            className="plantao-page-pricing-card"
            aria-labelledby="basic-plan"
          >
            <div className="plantao-page-pricing-card-header">
              <h3 id="basic-plan">Plano Básico</h3>
              <p className="plantao-page-pricing-price">Gratuito</p>
            </div>
            <ul className="plantao-page-pricing-features">
              <li className="pricing-section-item">Gestão de Plantões</li>
              <li className="pricing-section-item">Agenda Médica</li>
              <li className="pricing-section-item">Controle de Atendimentos</li>
              <li className="pricing-section-item">
                Cadastro de Pacientes e Clínicas
              </li>
              <li className="pricing-section-item">
                Acesso Limitado ao Prontuário Digital
              </li>
            </ul>
            <a
              href="https://plantao.prontuariofacil.com.br/cadastrar"
              id="botao-assinatura-gratis"
              className="plantao-page-pricing-button"
              role="button"
            >
              Começar Agora
            </a>
          </article>

          {/* Plano Premium (destacado) */}
          <article
            className="plantao-page-pricing-card plantao-page-pricing-card-featured"
            aria-labelledby="professional-plan"
          >
            <div className="plantao-page-pricing-card-header">
              <h3 id="professional-plan">Plano Premium</h3>
              <p className="plantao-page-pricing-price">R$ 49/mês</p>
            </div>
            <p className="plantao-page-pricing-recommendation">Mais Popular</p>
            <ul className="plantao-page-pricing-features">
              <li className="pricing-section-item">
                Inclui todas as funções do Plano Básico
              </li>
              <li className="pricing-section-item">Gestão de Recebíveis</li>
              <li className="pricing-section-item">Suporte Prioritário</li>
              <li className="pricing-section-item">
                Geração de Receitas e Atestados Médicos
              </li>
              <li className="pricing-section-item">Consulta CID/TUSS</li>
              <li className="pricing-section-item">
                Relatórios Financeiros Avançados
              </li>
              <li className="pricing-section-item">
                Acesso Completo ao Prontuário Digital Seguro
              </li>
            </ul>
            <a
              href="https://plantao.prontuariofacil.com.br/cadastrar"
              id="botao-assinatura-premium"
              className="plantao-page-pricing-button"
              role="button"
            >
              Assinar Agora
            </a>
          </article>

          {/* Plano Empresarial */}
          <article
            className="plantao-page-pricing-card"
            aria-labelledby="enterprise-plan"
          >
            <div className="plantao-page-pricing-card-header">
              <h3 id="enterprise-plan">Plano Empresarial</h3>
              <p className="plantao-page-pricing-price">R$ 199/mês</p>
            </div>
            <ul className="plantao-page-pricing-features">
              <li className="pricing-section-item">
                Inclui tudo do Plano Premium
              </li>
              <li className="pricing-section-item">
                Gestão de Equipes Médicas
              </li>
              <li className="pricing-section-item">
                Telemedicina com Videochamada
              </li>
              <li className="pricing-section-item">
                Personalização de Relatórios e Dashboards
              </li>
              <li className="pricing-section-item">
                Automação de Processos Administrativos
              </li>
              <li className="pricing-section-item">Suporte Técnico Dedicado</li>
              <li className="pricing-section-item">
                Integração com ERPs e Sistemas de Pagamento
              </li>
            </ul>
            <a
              href="https://plantao.prontuariofacil.com.br/cadastrar"
              id="botao-assinatura-empresarial"
              className="plantao-page-pricing-button"
              role="button"
            >
              Assinar Agora
            </a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default PricingSection
