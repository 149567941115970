import React from 'react';
import './SecuritySection.css';

const SecuritySection = () => {
    return (
        <section id="security" className="plantao-page-security-section" aria-labelledby="security-heading">
            <div className="plantao-page-security-content">
                <h2 id="security-heading">Segurança e Confiabilidade em Cada Etapa</h2>
                <p>
                    O Plantão Fácil utiliza criptografia de ponta e segue as melhores práticas de segurança para garantir que os dados dos médicos e pacientes estejam sempre protegidos.
                    Nossa plataforma está totalmente em conformidade com a **LGPD** (Lei Geral de Proteção de Dados) e outras regulamentações internacionais de segurança, como a **HIPAA**.
                </p>
                <p>
                    Todos os dados são criptografados utilizando **AES-256**, a mesma tecnologia usada por instituições financeiras, garantindo que suas informações estejam seguras a cada etapa do processo.
                </p>
                <div className="plantao-page-security-certifications" aria-label="Certificações de Segurança">
                    {/* Aqui você pode adicionar os ícones de certificação de segurança quando necessário */}
                    {/* <SecurityIcon1 aria-label="Certificação de Segurança 1" />
                    <SecurityIcon2 aria-label="Certificação de Segurança 2" />
                    <SecurityIcon3 aria-label="Certificação de Segurança 3" /> */}
                </div>
            </div>
        </section>
    );
};

export default SecuritySection;
