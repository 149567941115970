import React from "react"
import { FaUserCircle } from "react-icons/fa" // Ícone de usuário
import "./TestimonialsSection.css"

const TestimonialsSection = () => {
  return (
    <section
      id="testimonials"
      className="plantao-page-testimonials-section"
      aria-labelledby="testimonials-heading"
    >
      <div className="plantao-page-testimonials-content">
        <h2 id="testimonials-heading">
          O Que os Médicos Dizem sobre o Plantão Fácil
        </h2>
        <div className="plantao-page-testimonials-grid">
          <article className="plantao-page-testimonial-item">
            <blockquote>
              "O Plantão Fácil transformou completamente a forma como organizo
              meus plantões. Agora economizo 2 horas por semana e tenho total
              controle sobre meus recebíveis. É uma ferramenta indispensável."
            </blockquote>
            <div className="plantao-page-testimonial-author">
              <FaUserCircle
                size={60}
                color="#159a80"
                className="plantao-page-testimonial-avatar"
              />
              <div>
                <h4>Dr. João Silva</h4>
                <span>Cardiologista</span>
              </div>
            </div>
          </article>
          <article className="plantao-page-testimonial-item">
            <blockquote>
              "Com o Plantão Fácil, minha clínica conseguiu integrar a
              telemedicina e otimizar os atendimentos. O sistema é simples,
              intuitivo e trouxe mais eficiência para a equipe."
            </blockquote>
            <div className="plantao-page-testimonial-author">
              <FaUserCircle
                size={60}
                color="#159a80"
                className="plantao-page-testimonial-avatar"
              />
              <div>
                <h4>Dra. Maria Oliveira</h4>
                <span>Clínica Geral</span>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}

export default TestimonialsSection
